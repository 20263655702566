<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> {{caption}}
        <div class="card-header-actions">
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('active')" class="nav-item">
              <a class="nav-link" :class="tab == 'active' ? 'active' : ''" aria-current="page">Activas <span v-show="totals.active >= 0">({{totals.active}})</span></a>
            </li>
            <li @click="changeTab('canceled')" class="nav-item">
              <a class="nav-link" :class="tab == 'canceled' ? 'active' : ''">Inactivas <span v-show="totals.canceled >= 0">({{totals.canceled}})</span></a>
            </li>
          </ul>    
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 10 : 10"
            :dark="dark"
            tableFilter
            itemsPerPageSelect
            pagination
            class="listing"
          >
            <template slot="items" slot-scope="props" v-if="props.item.rfc == 'VECR'"></template>
            <template #name="{item}"><td><b>{{ item.rfc }}</b><br>{{ item.fiscal_name }}<br>{{ item.email }}</td></template>
            <template #fiscal_type="{item}"><td class="w100 text-center">{{ item.fiscal_type == "physical" ? "Física" : "Moral" }}</td></template>
            <template #package="{item}"><td class="w250 text-center"><b>{{ item.package.name }}</b><br><br>{{ item.stamps }} Timbres<br>{{ item.deadline | date }}</td></template>
            <template #actions="{item}">
              <td class="table_actions b4">
                <table style="padding: 0px; margin: auto; ">
                 <tr>
                  <td style="padding: 0px; border: 0px !important;">
                    <CButton color="info" size="sm" @click="viewAccount(item)">Ver Cuenta</CButton>
                  </td>
                  <td style="padding: 0px; border: 0px !important;">
                    <CDropdown color="info" togglerText="" size="sm">
                      <CDropdownItem href="#" @click="viewAccount(item)">Ver Cuenta</CDropdownItem>
                      <CDropdownDivider/>
                      <CDropdownItem href="#" v-show="item.active == 1" @click="deactiveAccount(item)">Desactivar</CDropdownItem>
                      <CDropdownItem href="#" v-show="item.active == 0" @click="activeAccount(item)">Activar</CDropdownItem>
                      <CDropdownItem href="#" v-show="item.active == 0" @click="deleteAccount(item)">Eliminar</CDropdownItem>
                    </CDropdown>
                  </td>
                  </tr>
                </table>
              </td>
            </template>
          </CDataTable>
          <ActiveModal ref="activeModal" @active="activeProcess" @deactive="deactiveProcess" @delete="deleteProcess"></ActiveModal>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import account_ws from '../../services/account';
import store from '../../store'
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

import ActiveModal from './modals/ActiveModal.vue'

moment.tz.setDefault('America/Mexico_city');

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'AccountsTable',
  components: {'v-date-picker': DatePicker, ActiveModal },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [
        {key: 'fiscal_type', label: 'Tipo'},
        {key: 'name', label: 'Nombre de la Cuenta'},
        {key: 'package', label: 'Paquete'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      tab: 'active',
      totals: {
        active: -1,
        canceled: -1
      },
      accounts: [],
      accountSelect: "",
      accountsOptions: [],
      range: {
        start: new moment().startOf('month').toDate(),
        end: new moment().endOf("month").toDate(),
      },
      search: ''
    }
  },
  mounted: async function() {
    if(this.$store.state.account && this.$store.state.account != 1 && this.$store.state.account != 71){
      alert(this.$store.state.account);
      window.location.href = "/#/invoices";
    }
  },
  methods: {
    async changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;

        this.$emit("refresh", tab);
      }
    },
    activeAccount(account){
      this.$refs.activeModal.activationModal(account);
    },
    activeProcess (item) {
      this.$emit("refresh", "deactive");
    },
    deactiveAccount(account){
      this.$refs.activeModal.desactivationModal(account);
    },
    deactiveProcess (account) {
      this.$emit("refresh", "active");
    },
    deleteAccount(account){
      this.$refs.activeModal.deleteModal(account);
    },
    deleteProcess (item) {
      this.$emit("refresh", "deactive");
    },  
    viewAccount (item) {
      window.location.href = "/#/accounts/"+item.id+"/view";
    },  
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    
  },
  computed: {

  }
}
</script>
